*, *:before, *:after {
    box-sizing: border-box;
}

body{
    margin: 0;
}

a{
    text-decoration: none;
    color: inherit;
}

img{
    max-width: 100%;
}

button {
    cursor: pointer;
}