$font: 'minion-pro', serif;

$dark: #0A0A14;
$gold: #D6B052;
$purple: #542F7C;
$light: #E0E0E0;

$lighter: #eceaea;
$themeColor: #542F7C;
$themeColorDarker: #442764;

$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;