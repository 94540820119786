._gold{
    color: $gold;
}

._fullscreen{
    position: fixed;
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: contain; /* or object-fit: cover; */
    left:50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}