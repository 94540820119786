._grid{
    width: calc(100% - 40px);
    margin: 0 auto;
    max-width: 1400px;
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    ._column{
        width: 100%;
    }
    &._form-grid{
        max-width: 800px;
    }
    &._form-grid-small{
        max-width: 400px;
    }
    &._grid-2{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(50% - 20px);
            }
        }
    }
    &._grid-3{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(33.33% - 30px);
            }
        }
    }
    &._grid-4{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(25% - 30px);
            }
        }
    }
    @media(min-width: $tablet){
        width: calc(100% - 60px);
        column-gap: 30px;
    }
    @media(min-width: $laptop){
        width: calc(100% - 80px);
        column-gap: 40px;
    }
}

._full-width-grid{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    > ._column{
        width: 100%;
        @media(min-width: $tablet){
            width: 50%;
        }
    }
}

footer{
    background-color: $purple;
    color: #fff;
    padding: 60px 0;
    ._panel{
        text-align: center;
    }
    ._footer-logo{
        width: 138px;
    }
    ._footer-nav-list{
        padding-left: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        column-gap: 10px;
        row-gap: 10px;
        flex-wrap: wrap;
    }
    ._awaredog{
        width: 180px;
    }
    ._footer-horline-social-media{
        display: flex;
        justify-content: space-between;
        align-items: center;
        ._horline{
            height: 1px;
            background-color: $gold;
            width: calc(50% - 100px);
        }
    }
}

._section-covid-banner{
    padding: 10px 0;
    ._panel{
        display: flex;
        justify-content: center;
        column-gap: 30px;
        row-gap: 30px;
        flex-wrap: wrap;
        align-items: center;
    }   
    ._covid{
        width: 250px;
    }
}