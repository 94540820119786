body{
    font-family: $font;
    color: $dark;
}

p, input, textarea, select, label{
    font-size: 20px;
    font-family: $font;
    @media(min-width: $laptop){
        font-size: 16px;
    }
}

._heading-1{
    font-size: 31px;
    line-height: 32px;
    font-weight: 400;
    margin: 30px 0;
    @media(min-width: $laptop){
        font-size: 38px;
        line-height: 40px;
    }
    @media(min-width: 1253px){
        font-size: 40px;
        line-height: 43px;
    }
}

._heading-2{
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    @media(min-width: $tablet){
        font-size: 25px;
        line-height: 25px;
    }
}

._heading-3{
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    @media(min-width: $laptop){
        font-size: 22px;
        line-height: 28px;
    }
}

p{
    font-size: 20px;
    line-height: 28px;
    @media(min-width: $laptop){
        font-size: 16px;
        line-height: 26px;
    }
}

._special-heading{
    font-size: 50px;
    line-height: 60px;
    @media(min-width: 1253px){
        font-size: 70px;
        line-height: 85px;
    }
}

._button{
    background-color: $purple;
    border: none;
    border-radius: 3px;
    padding: 10px 40px;
    min-height: 50px;
    min-width: 120px;
    text-align: center;
    color: #fff;
    font-family: $font;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;

}

._button-2{
    background-color: transparent;
    border: 1px solid $purple;
    color: $purple;
}

._input-row, ._form-row{
    @media(min-width: $tablet){
        column-gap: 30px;
        row-gap: 20px;
        display: flex;
        justify-content: center;
    }
}

._input-group, ._form-group {
    position: relative;
    padding: 15px 0 0 0;
    width: 100%;
    label{
        display: block;
        font-weight: 300;
        color: $dark;
        margin-bottom: 7px;
    }
}

._input, ._form-element{
    width: 100%;
    display: block;
    background-color: #fff;
    color: $dark;
    min-height: 40px;
    border: 1px solid $light;
    padding: 10px;
}

._error-message{
    color: red;
    font-size: 16px;
    font-style: italic;
}

._error-message-alert{
    color: red;
    margin: 10px 0;
    font-size: 18px;
    font-style: italic;
}

._error{
    input, select{
        border-color: red;
    }
}

._checkbox-row{
    display: flex;
    align-items: center;
    ._form-element{
        width: 40px;
    }
}

._form-group-checkbox{
    margin: 30px 0 30px 0;
}

._form-group-textarea{
    width: 100%;
}

._submit-button{
    @include transition(0.5s);
    position: relative;
    top: 0;
    left: 0;
    @media (min-width: $laptop){
        &:hover{
            opacity: 0.9;
        }
    }
    // &._sending{
    //     left: 100vw;
    // }
}

._social-nav-list{
    padding-left: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    a{
        display: flex;
        align-items: center;
    }
    svg{
        fill: $gold;
    }
}




._checkbox-2{
    ._item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    
    label {
        display: flex;
        margin: auto;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    
    input {
        position: absolute;
        transform: scale(0);
    }
    
    input:checked ~ ._checkbox {
        transform: rotate(45deg);
        width: 14px;
        height: 28px;
        margin: 0 7px 0 13px;
        border-color: $gold;
        border-width: 3px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-radius: 0;
    }
    
    ._checkbox {
        display: block;
        width: inherit;
        height: inherit;
        border: 1px solid rgb(187, 187, 187);
        border-radius: 0px;
        transition: all 0.2s cubic-bezier(0, 0.01, 0.23, 0.8);
    }
      
}

._scroll-down-btn{
    display: none;
    align-items: center;
    column-gap: 3px;
    transform: rotate(-90deg);
    width: auto;
    background-color: transparent;
    border: none;
    position: relative;
    left: -45px;
    top: 80px;
    ._line{
        width: 30px;
        height: 1px;
        background-color: $dark;
    }
    @media(min-width: $laptop){
        display: flex;
    }
}

._social-share{
    display: flex;
    column-gap: 10px;
    justify-content: center;
    @media(min-width: $tablet){
        justify-content: flex-start;
    }
}

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	margin-top: 20px;
	cursor: pointer;
}

.upload-btn, .upload-btn:hover {
    border: none;
	background: $purple;
	color: #FFF;
}

.upload-btn {
	text-align: center;
	min-width: 300px;
	cursor: pointer;
	padding: 12px 35px;
	font-family: $font;
    font-size: 20px;
    @media(min-width: $laptop){
        font-size: 16px;
    }
}

.upload-btn-wrapper input[type="file"] {
	cursor: pointer;
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}