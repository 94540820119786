._section-competition-form{
    margin-bottom: 60px;
    ._panel{
        max-width: 800px;
        margin: 0 auto;
    }
    h4{
        color: $gold;
        text-align: center;
        margin-bottom:0px;
        margin-top: 60px;
    }
    @media(min-width: $laptop){
        margin-bottom: 100px;
    }
}

._section-1{
    text-align: center;
    ._panel{
        padding: 30px 0 60px 0;
        ._heading-1{
            margin-left: auto;
            margin-right: auto;
            max-width: 350px;
        }
    }
    ._logo{
        width: 180px;
    }
    ._img-winter{
        width: 450px;
    }
    ._heroImg{
        display: none;
    }
    ._heroImg-cropped{
        display: block;
    }
    &.piatto{
        ._button{
            width: 100%;
        }
    }
    @media(min-width: $tablet){
        &.piatto{
            ._button{
                width: unset;
            }
        }
        ._heroImg-cropped{
            display: none!important;
        }
        text-align: left;
        position: relative;
        height: calc(50vw + 30px);
        min-height: 584px;
        ._grid{
            height: 100%;
            align-items: center;
        }
        ._panel{
            // padding: 0;
            max-width: 520px;
            ._heading-1{
                margin-left: 0;
            }
        }
        ._logo{
            margin-right: 20px;
        }
        ._img-winter{
            width: 200px;
        }
        ._heroImg{
            display:block;
            position: absolute;
            top: 0;
            right: 0;
            height: calc(100vh - 30px);
            max-height: 50vw;
        }
        &.piatto{
            ._heroImg{
                height: 100vh;
            }
        }
    }
    @media(min-width: $laptop){
        ._img-winter{
            width: 350px;
        }
        ._logo{
            width: 220px;
        }
        ._panel{
            ._heading-1{
                max-width: 420px;
            }
        }
    }
    @media(min-width: 1253px){
        ._img-winter{
            width: 450px;
        }
        ._panel{
            ._heading-1{
                max-width: 460px;
            }
        }
    }
}

._section-2{
    padding: 60px 0;
    ._inner-panel{
        padding: 30px 0;
    }
    ._fieldbarBen{
        margin-top: 30px;
    }
    @media(min-width: $tablet){
        ._inner-panel{
            max-width: 700px;
            margin: 0 auto;
            padding: 0;
        }
        ._panel{
            display: flex; 
            justify-content: space-between;
            align-items: center;
            column-gap: 30px;
        }
        ._img-fieldbar{
            max-width: 500px;
            width: 100%;
            min-width: 350px;
        }
    }
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

._explore-text{
    color: $gold;
    font-size: 30px;
    font-weight: 600;
}

._hero-1{
    @media(min-width: $tablet){
        display: none;
    }
}

._hero-2{
    display: none;
    @media(min-width: $tablet){
        display: block;
    }
}